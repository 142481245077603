import React, { Component } from "react";
import withUnmounted from '@ishawnwang/withunmounted';
import { connect } from "react-redux";

function mapStateToProps(state) {
    return {
      llConfig: state.llConfig,
      storeCountries: state.countries
    };
  }

class Skeleton extends Component {

 
  UNSAFE_componentWillMount(){
    const { history } = this.props;
    var ll_hash = this.props.llConfig.llHash;
    if(!ll_hash){
        if(history){
            history.push('/404');
        }
    }
  }

  UNSAFE_componentWillReceiveProps(props){
      const { history } = props;
      if(props.cartInfo === false){
          history.push('/404');
      }
  }


  render(){
      return(
          <React.Fragment>
            
            <div>
                <div className="bg-white">
                    <div className="left-div width100"></div>

                    <div className="left-div">
                    <div className="ph-item border-0 p-0">
                        <div className="ph-col-4 pl-0 pr-5">
                            <div className="ph-avatar"></div>
                        </div>

                        <div className="pr-0 pl-0">
                            <div className="ph-row">
                                <div className="ph-col-12 big empty"></div>
                                <div className="ph-col-12 big">
                                <div className="ph-picture mb-0"></div>
                                <div className="ph-picture mb-0"></div>
                                <div className="ph-picture mb-0"></div>
                                <div className="ph-picture mb-0"></div>
                                <div className="ph-picture mb-0"></div>
                                <div className="ph-picture mb-0"></div>
                                </div>
                            </div>
                        </div>

                        <div className="ph-col-12">     
                            <div className="row">
                                <div className="ph-col-12 mb-1">     
                                <div className="ph-row row">
                                    <div className="ph-col-8 mb-5 mt-5"></div> 
                                    <div className="ph-col-8 mb-1"></div> 
                                    </div>
                                </div> 
                                <div className="ph-col-6 pr-1">
                                    <div className="ph-row">
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    </div>
                                </div>
                                <div className="ph-col-6 pl-1">
                                    <div className="ph-row">
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="ph-col-12 mt-5">     
                                <div className="ph-row row">
                                    <div className="ph-col-8 mb-1"></div> 
                                    </div>
                                </div> 
                                <div className="ph-col-12 mb-2">
                                    <div className="ph-row">
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    </div>
                                </div>
                                <div className="ph-col-12 mb-2">
                                    <div className="ph-row">
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div> 
                                    </div>
                                </div> 
                                <div className="ph-col-12 mb-2">
                                    <div className="ph-row">
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    </div>
                                </div>
                                <div className="ph-col-4">
                                    <div className="ph-row">
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    </div>
                                </div> 
                                <div className="ph-col-4 px-1">
                                    <div className="ph-row">
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    </div>
                                </div>
                                <div className="ph-col-4">
                                    <div className="ph-row">
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    </div>
                                </div>

                                <div className="ph-col-6 empty">
                                </div>
                                <div className="ph-col-6">
                                    <div className="ph-row pl-5">
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                    </div>
                </div>

                <div className="bg-gray">
                <div className="order-summery">
                    <div className="ph-item border-0 p-0 bg-transparent">

                        <div className="ph-col-12">     
                            <div className="row">
                                <div className="ph-col-2 mb-2">
                                    <div className="ph-row">
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    </div>
                                </div>
                                <div className="ph-col-8 px-1">
                                    <div className="ph-row"> 
                                    <div className="ph-col-12 mb-0 empty"></div>
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0 empty"></div>
                                    <div className="ph-col-12 mb-0"></div> 
                                    </div>
                                </div>

                                <div className="ph-col-2">
                                    <div className="ph-row">
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    </div>
                                </div>
                                <div className="ph-col-4 mb-2">
                                    <div className="ph-row">
                                    <div className="ph-col-12 mb-0"></div>
                                    </div>
                                </div> 
                                <div className="ph-col-4 mb-2 empty">
                                    <div className="ph-row empty">
                                    <div className="ph-col-12 mb-0 empty"></div>
                                    </div>
                                </div> 
                                <div className="ph-col-4 mb-2">
                                    <div className="ph-row">
                                    <div className="ph-col-12 mb-0"></div>
                                    </div>
                                </div> 
                                <div className="ph-col-4 mb-2">
                                    <div className="ph-row">
                                    <div className="ph-col-12 mb-0"></div>
                                    </div>
                                </div> 
                                <div className="ph-col-4 mb-2 empty">
                                    <div className="ph-row empty">
                                    <div className="ph-col-12 mb-0 empty"></div>
                                    </div>
                                </div> 
                                <div className="ph-col-4 mb-2">
                                    <div className="ph-row">
                                    <div className="ph-col-12 mb-0"></div>
                                    </div>
                                </div> 
                                <div className="ph-col-4 mb-2">
                                    <div className="ph-row">
                                    <div className="ph-col-12 mb-0"></div>
                                    </div>
                                </div> 
                                <div className="ph-col-4 mb-2 empty">
                                    <div className="ph-row empty">
                                    <div className="ph-col-12 mb-0 empty"></div>
                                    </div>
                                </div> 
                                <div className="ph-col-4 mb-2">
                                    <div className="ph-row">
                                    <div className="ph-col-12 mb-0"></div>
                                    </div>
                                </div> 

                                <div className="ph-col-4 mb-2">
                                    <div className="ph-row">
                                    <div className="ph-col-12 mb-0"></div>
                                    </div>
                                </div> 
                                <div className="ph-col-4 mb-2 empty">
                                    <div className="ph-row empty">
                                    <div className="ph-col-12 mb-0 empty"></div>
                                    </div>
                                </div> 
                                <div className="ph-col-4 mb-2">
                                    <div className="ph-row">
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div>
                                    </div>
                                </div> 
                            </div>
                            
                        </div>
                    </div>
                </div>

                </div>
                
            </div>
          </React.Fragment>
      );
  }
}

const ConnectedSkeleton = connect(
    mapStateToProps,
    null
)(Skeleton);
  
export default withUnmounted(ConnectedSkeleton);