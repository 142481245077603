import React, { Component } from "react";
import Checkout from "../classes/checkout";
import LaddaButton, { L, SLIDE_UP } from "react-ladda";
import env from '../env';
import noImage from "../images/no-image.svg";
import ReactImageFallback from "react-image-fallback";
import withUnmounted from '@ishawnwang/withunmounted';
import ls from "local-storage";
import cartEmpty from "../images/empty_cart.png";
import swal from 'sweetalert';
import OneClickUpsell from './OneClickUpsell';
import SmileIOPage from './SmileIOPage';
import {connect} from 'react-redux';

ls.backend(sessionStorage);

const mapStateToProps = (state) => {
  return {
      llConfig: state.llConfig
  };
}

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false
    this.getUpdateProps = this.getUpdateProps.bind(this);

  }

  componentDidMount() {
    this._isMounted = true    
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getUpdateProps(e){
    let oneClickUpsells = ls.get("oneClickUpsells");
    this.props.updateTax(e);
    this.setState({oneClickUpsells:oneClickUpsells});
  }


  render() {

    return (
      <div className="order-summery">
        <div className="order-summery-area">
          <div className="order-summery-top scrollbar" id="style-1">
            <table className="table">
              <tbody className="prod-items-list">
                {<Products cartInfo={this.props.cartInfo} updateTax={this.props.updateTax} tax={this.props.tax} {...this.props}/>}
              </tbody>
            </table>
          </div>
          {<ProductCharges updateStep={this.props.updateStep} llConfig={this.props.llConfig} updateTax={this.props.updateTax} tax={this.props.tax} updateCart={this.props.updateCart} cartInfo={this.props.cartInfo}  {...this.props} />}
        </div>
        <div className="one-click-upsell-area">
          { !this.props.isMobile ? <OneClickUpsell cartInfo={this.props.cartInfo}  returnProps={(e)=>this.getUpdateProps(e)}/> : ""}
        </div>
      </div>
    );
  }
}

const ConnectedSidebar = connect(
  mapStateToProps,
  null
)(Sidebar);

export default withUnmounted(ConnectedSidebar);

class Products extends Component {
  constructor(props) {
    super(props);
    this.img =  noImage;
    this.state = {
      cartInfo: this.props.cartInfo ? this.props.cartInfo : null,
      processUpsell: null,
      showBundleContent: true,
      bundlesToShow: []
    };
    this.getDiscountProdcutDetail = this.getDiscountProdcutDetail.bind(this);
    this.removeItemFromCart = this.removeItemFromCart.bind(this);
  }

  async removeItemFromCart(event, rowIndex, variant_id = false, bill_id = false){
    var prodId = event && event.target && event.target.getAttribute("data-prod-id") ? event.target.getAttribute("data-prod-id") : false;
    let removeLinkEle = document.querySelectorAll(".remove-product-from-cart");
      swal(
        "Are you sure?",
        (removeLinkEle && removeLinkEle.length > 0 && removeLinkEle[rowIndex].innerHTML === 'Remove') ? "This will remove the item from the cart." : "This will add the item from the cart.",
        "info",
        {buttons: ["Close", "Ok"]}).then((value) => {
          if (value) {
            if(removeLinkEle && removeLinkEle.length > 0){
              removeLinkEle[rowIndex].innerHTML = (removeLinkEle && removeLinkEle.length > 0 && removeLinkEle[rowIndex].innerHTML === 'undo') ? "Undo" : "Remove";
            }
            ls.remove('totalOfferDiscount');
            ls.remove('ordertotal');
            this.props.removeCartItem(prodId, rowIndex, variant_id, bill_id);
          }
      });
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.cartInfo) {
      
      this.setState({ cartInfo: nextProps.cartInfo });
      const { history } = this.props
      if (nextProps.cartInfo.checkout_online_status === false) {
        history.push('/404')
      }
    }
  }

 async componentWillUnmount() {
    document.removeEventListener('click', this.bundleProductOutsideClick);
  }

  async componentDidMount() {
    document.addEventListener('click', this.bundleProductOutsideClick);
    let upsellDetails = await Checkout.getProcessUpsellData();

    this.setState({ processUpsell: upsellDetails });

  }

  async updateProductQty(stateQuantity,cartInfo, productId,shopify_id,rowIndex){
    await this.props.updateCartItemQuantity(stateQuantity,cartInfo, productId,shopify_id,rowIndex)
    let tax = await Checkout.calculateTax() ;
    this.props.updateTax(tax)
  }

  bundleProductOutsideClick = (event) => {
    let bundlesToShow = this.state.bundlesToShow;
    if(event.target?.parentElement?.className === "bundle-li"){
      event.stopPropagation();
    }else if(event.target.className !== "clickable"){
      if(bundlesToShow && Object.keys(bundlesToShow).length > 0){
        bundlesToShow = [];
        this.setState({bundlesToShow});
      }
    }
  }
 

  showBundle = (prod_crm_id) => {
    let bundlesToShow = this.state.bundlesToShow;
    if(this.state.bundlesToShow.includes(prod_crm_id)){
      bundlesToShow.pop(prod_crm_id);
    }else{
      bundlesToShow.push(prod_crm_id);
    }
    this.setState({bundlesToShow});
  }

 getDiscountProdcutDetail(prodcutId, key = null){
    let prodctArray = {};  
    try{
        
        if(!prodcutId) return prodctArray;

        let itemDiscount = ls.get("tax");
        itemDiscount = itemDiscount && 'line_items' in itemDiscount && itemDiscount.line_items ? itemDiscount.line_items : [];

        if(!itemDiscount) return prodctArray;

        for(var i=0; i<Object.keys(itemDiscount).length ; i++){

              if(Object.keys(prodctArray).length>0){
                break;
              }

              if(itemDiscount[i].id.toString().indexOf('-')>-1){
                // order total calculate return product id in format 4309-64883 , crm prod id - crm variant id
                let splitProdcut = itemDiscount[i].id.split("-");
                // checking with index key , as the response dont have billing id so it will create issue when product with same variant in cart
                if(splitProdcut[1].toString() === prodcutId.toString() && parseInt(key) === parseInt(i)){
                  prodctArray = itemDiscount[i];
                }
              }else if((itemDiscount[i]['id']).toString() === prodcutId.toString()){
                prodctArray =  itemDiscount[i];
                
              }
        }
        
        return prodctArray;
      }catch(e){
        console.log(e);
        return prodctArray;
      }
  }

  render() {
    
    
    let products = this.state.cartInfo && Object.keys(this.state.cartInfo).length > 0 && this.state.cartInfo.shopDetails.products ? this.state.cartInfo.shopDetails.products : {};
    let giftItems = (ls.get('giftItems') !== false) ? ls.get('giftItems') : false;
    let items_2 = [];

    let processUpsell = this.state.processUpsell && Object.keys(this.state.processUpsell).length > 0 && this.state.processUpsell ? this.state.processUpsell : {};
    let checkout_billing_model_ids = this.state.cartInfo && this.state.cartInfo.shopDetails.checkout_billing_model_ids ? this.state.cartInfo.shopDetails.checkout_billing_model_ids : [];
    let all_billing_model_ids = this.state.cartInfo && this.state.cartInfo.billingModels ? this.state.cartInfo.billingModels : [];
    let all_bundle_products = this.state.cartInfo && this.state.cartInfo.bundle_products ? this.state.cartInfo.bundle_products : null;

    let totalOfferDiscount = 0;
    let removed_items = (ls.get('removed_items')) ? ls.get('removed_items') : false;
    let trav_key = 0;
    products && Object.keys(products).forEach(async (key)=>{
    
      let inKey = products[key];
      let price = inKey.price ? parseFloat(inKey.price).toFixed(2) : '0.00';
      let qty = 'quantity' in inKey ? parseInt(inKey.quantity) : 1;
      let billingLabel = '';
      let bundleProdsHtml = [];
      let bundleProducts = [];

      let attributesHtml = [];
      //For billing Label
      if ('product_billing_model_id' in inKey && inKey.product_billing_model_id && checkout_billing_model_ids) {
        billingLabel = checkout_billing_model_ids.find((element) => {
          return element.key === inKey.product_billing_model_id;
        })
        billingLabel = billingLabel ? billingLabel.billing_model_label : '';
      }
       if (all_billing_model_ids) {
         billingLabel = all_billing_model_ids.find((element) => {
           return element.id === inKey.product_billing_model_id;
         })
         billingLabel = billingLabel ? billingLabel.name : '';
       }

       if (all_bundle_products) {
          all_bundle_products && Object.keys(all_bundle_products).forEach(async (bund_prod_id) => {
            if(bund_prod_id === inKey.product_crm_id){
              bundleProducts = all_bundle_products[bund_prod_id];
            }
          });
          Object.values(bundleProducts).forEach((attrValues) => {
            bundleProdsHtml.push(<li className="bundle-li" key={attrValues.id} onClick={(e) => {this.bundleProductOutsideClick(e)}}><div className="bundle-li" ><img className="bundle-li"  src={attrValues.product_img_url ? attrValues.product_img_url : noImage} alt={attrValues.name} /></div><p className="bundle-li" ><b>{attrValues.quantity} x {attrValues.name}</b></p></li>);
          });
        }

      if (billingLabel !== '') {
        attributesHtml.push(<span className="product-color" key={Math.random()}>{billingLabel}</span>);
      }
      //for attribute Detail
      if ('attributes' in inKey && inKey.attributes) {
        Object.values(inKey.attributes).forEach((attrValues) => {
          attributesHtml.push(<span className="product-color text-capitalize" key={Math.random()}>{attrValues && attrValues.attribute && attrValues.attribute.name ? attrValues.attribute.name : ''} : {attrValues && attrValues.attribute && attrValues.attribute.option ? attrValues.attribute.option.name : ''}</span>);
        });
      }

      //attribut variant for dynamic product
      if ('variants' in inKey && inKey.variants) {
        Object.values(inKey.variants).forEach((attrVal) => {
          if (attrVal && attrVal.attribute_name && attrVal.attribute_value) {
            attributesHtml.push(<span className="product-color text-capitalize" key={Math.random()}>{attrVal.attribute_name} : {attrVal.attribute_value}</span>);
          }
        });
      }


      let productCrmID = inKey && 'product_crm_id' in inKey ? inKey.product_crm_id : null;
      if(inKey && 'crm_variant_id' in inKey && inKey.crm_variant_id){
        productCrmID =  inKey.crm_variant_id;
      }
      // when there is custom link of shopify and crm variant 
      if(inKey && 'variant_product_crm_id' in inKey && inKey.variant_product_crm_id){
        productCrmID =  inKey.variant_product_crm_id;
      }
      let itemFromDiscountArr = this.getDiscountProdcutDetail(productCrmID, trav_key)
      let productDiscount = itemFromDiscountArr && Object.keys(itemFromDiscountArr).length>0 ? itemFromDiscountArr.discount : {};
      let discountPrice = productDiscount && Object.keys(productDiscount).length > 0 ?('percent' in productDiscount && productDiscount.percent >0 ? (inKey.price * productDiscount.percent)/100 : (inKey.price - parseFloat(itemFromDiscountArr.unit_price.toString().replace(/,/g, ''),10).toFixed(2) )):0; 
      if(( removed_items && removed_items.indexOf(key) > -1)){
        
      }else{
        discountPrice = parseFloat(discountPrice).toFixed(2);
        totalOfferDiscount = totalOfferDiscount +  (discountPrice * qty);
        trav_key++;
      }

      let arrayIndex =items_2.length;
      let max_qty = ((this.state.cartInfo.shopDetails.crm_product_details && this.state.cartInfo.shopDetails.crm_product_details !== null && Object.keys(this.state.cartInfo.shopDetails.crm_product_details).length > 0 && key in this.state.cartInfo.shopDetails.crm_product_details && this.state.cartInfo.shopDetails.crm_product_details[key].product_max_quantity !== undefined) ? this.state.cartInfo.shopDetails.crm_product_details[key].product_max_quantity : env.DEFAULT_MAX_PRODUCT_QTY)
      items_2.push(<>

        <tr key={key} className={ (removed_items !== false && removed_items.indexOf(key) > -1 ) ?  (window.location.pathname === '/thank-you' ? 'item-removed d-none' : 'item-removed') : "product"}>
          <td>
            <div className="product-image">
              <div className="product-image-inner">

                <img
                  src={inKey.product_img_url ? inKey.product_img_url : noImage}
                  alt={inKey.product_name}
                  className="product-thumbnail__image" />


              </div>
              <span>{qty}</span>
            </div>
          </td>
            <td>
            <span className={"product-name " + ( inKey.product_name.length > 24 ? "custom-tooltip": "")} data-title={inKey.product_name}>{ Checkout.setEcllipseOnString(inKey.product_name, 24) }</span>
              <span className="product-color">{ 'variation' in inKey ? inKey.variation : '' }</span>
              {attributesHtml}
              <span className="product-color">Item price : $ {discountPrice>0 ? <strike>{parseFloat(inKey.price).toFixed(2)}</strike> : `${parseFloat(inKey.price).toFixed(2)}`}</span>
              {discountPrice >0 && <span className="product-color">Offer price : $ {parseFloat(price - discountPrice).toFixed(2)}</span>}

            </td>
            <td className="hidden-box">{'quantity' in inKey ? inKey.quantity : 1 }</td>
            <td style={ { paddingRight : '0px' } }>
              {discountPrice >0 && <span className="product-price"> $ {parseFloat( (price - discountPrice) * qty).toFixed(2)}</span>}
              {Math.ceil(discountPrice)<1 && <span className="product-price">
                {this.state.cartInfo.currency ? this.state.cartInfo.currency : '$' } {parseFloat(price * qty).toFixed(2)}
              </span>}
              {
                     window.location.pathname !== '/thank-you' && !this.state.cartInfo.checkout_is_lander && Object.keys(bundleProdsHtml).length === 0 ? <div className="updateQty num-block">
                       <label>Quantity</label>
                     <div className="num-in">
                     <input type="text" className="in-num"   value={'quantity' in inKey ? inKey.quantity : 1 } min="1" readOnly={true}></input>
                     <span className={ "minus " + (inKey && inKey.quantity <= 1  ? "disable-qty" : "")}  onClick={(e)=> { this.updateProductQty(false, this.state.cartInfo, key, (inKey.product_shopify_id) ? inKey.product_shopify_id : 0,arrayIndex )  }}>-</span>
                     <span className={"plus " + (inKey.quantity >= max_qty ? "disable-qty" : "" )} onClick={(e)=> { this.updateProductQty(true, this.state.cartInfo, key, (inKey.product_shopify_id) ? inKey.product_shopify_id : 0,arrayIndex )  }}  >+</span>
                     </div><div className="clearfix"></div>
                   </div> : <div className="updateQty num-block" style={ { display : "none" } }>
                     <label>Quantity</label>
                     <div className="num-in">
                     <input type="text" className="in-num"  value={'quantity' in inKey ? inKey.quantity : 1 } min="1" readOnly={true}></input>
                     <span className={ "minus " + (inKey && inKey.quantity <= 1 ? "disable-qty" : "")}  onClick={(e)=> { this.updateProductQty(false, this.state.cartInfo, key, (inKey.product_shopify_id) ? inKey.product_shopify_id : 0,arrayIndex )  }}>-</span>
                     <span className={"plus " + (inKey.quantity >= max_qty ? "disable-qty" : "" )} onClick={(e)=> { this.updateProductQty(true, this.state.cartInfo, key, (inKey.product_shopify_id) ? inKey.product_shopify_id : 0 , arrayIndex )  }}  >+</span>
                     </div>
                   </div>
              }
              {
                     window.location.pathname !== '/thank-you' && !this.state.cartInfo.checkout_is_lander ? <span className="remove-product-from-cart" data-prod-id={inKey._id.$oid} onClick={(e) => { this.removeItemFromCart(e,arrayIndex, inKey.variant_id, inKey.product_billing_model_id) }}>{ ( removed_items !== false && removed_items.indexOf(key) > -1) ? 'Undo': 'Remove' }</span> :  <span className="remove-product-from-cart" data-prod-id={inKey._id.$oid} onClick={(e) => { this.removeItemFromCart(e,arrayIndex, inKey.variant_id) }}  style={ { display : "none" } }>{ ( removed_items !== false && removed_items.indexOf(key) > -1) ? 'Undo': 'Remove' }</span>
              }
              
            </td>
          </tr>
          {bundleProdsHtml && Object.keys(bundleProdsHtml).length > 0 &&
            <tr className="bundle-cont-three">
                <td colSpan="5">
                  { window.location.pathname !== '/thank-you' ? 
                    <>
                    <span className="clickable" onClick={e => this.showBundle(inKey.product_crm_id)}>{this.state.bundlesToShow.includes(inKey.product_crm_id) ? "Hide bundle contents" : "Show bundle contents"}</span>
                    {this.state.bundlesToShow.includes(inKey.product_crm_id) ? <div className="with-close"> <button onClick={e => this.showBundle(inKey.product_crm_id)}>&times;</button> <div className="clearfix"></div> <ul className="bundle-li" >{bundleProdsHtml} </ul></div> : '' }
                   </>
                   : <div className={ (removed_items !== false && removed_items.indexOf(key) > -1 ) ? 'item-removed d-none' : ''}>
                   <label className={"bundle-conent-text"}> {"Bundle contents"}</label>
                   { <ul style={{position:"static"}}> {bundleProdsHtml} </ul> }
                    </div>
                  }
                </td>
            </tr>
          }
      </>  );

     
    

    });


    //one click upsells
    let oneClickUpsellsatLocal = ls.get('oneClickUpsells');
    oneClickUpsellsatLocal && Object.keys(oneClickUpsellsatLocal).length > 0 && Object.values(oneClickUpsellsatLocal).forEach((inKey)=>{
      let price = inKey.price ? parseFloat(inKey.price).toFixed(2) : '0.00';
      let qty = 1;

      let productCrmID = inKey && 'variant_product_crm_id' in inKey && inKey.variant_product_crm_id ? inKey.variant_product_crm_id: inKey.product_crm_id;
      let itemFromDiscountArr = this.getDiscountProdcutDetail(productCrmID)
      let productDiscount = itemFromDiscountArr && Object.keys(itemFromDiscountArr).length>0 ? itemFromDiscountArr.discount : {};
      let discountPrice = productDiscount && Object.keys(productDiscount).length > 0 ?('percent' in productDiscount && productDiscount.percent >0 ? (inKey.price * productDiscount.percent)/100 : (inKey.price - parseFloat(itemFromDiscountArr.unit_price.toString().replace(/,/g, ''),10).toFixed(2) )):0; 
      
      totalOfferDiscount = totalOfferDiscount +  (discountPrice * qty);

      let billingLabel = '';
      let attributesHtml = [];
      
      if (all_billing_model_ids) {
        billingLabel = all_billing_model_ids.find((element) => {
          return element.id === inKey.product_billing_model_id;
        })
        billingLabel = billingLabel ? billingLabel.name : '';
      }

     if (billingLabel !== '') {
       attributesHtml.push(<span className="product-color" key={Math.random()}>{billingLabel}</span>);
     }

      items_2.push(

        <tr key={Math.random()}>
          <td>
            <div className="product-image">
              <div className="product-image-inner">

                <img
                  src={inKey.image_url && inKey.image_url.length >0  ? inKey.image_url[0] : noImage}
                  alt={inKey.name}
                  className="product-thumbnail__image" />


              </div>
              {!this.state.cartInfo.checkout_is_lander && <span>{qty}</span> }
            </div>
          </td>
          <td>
            {/* Long string tool-tip change */}
            <span className={"product-name " + ( inKey.name.length > 24 ? "custom-tooltip": "")} data-title={inKey.name}>{ Checkout.setEcllipseOnString(inKey.name, 24) }</span>
            <span className="product-color"></span>
            {attributesHtml}
            <span className="product-color">Item price : $ {discountPrice>0 ? <strike>{parseFloat(inKey.price).toFixed(2)}</strike> : `${parseFloat(inKey.price).toFixed(2)}`}</span>
              {discountPrice >0 && <span className="product-color">Offer price : $ {parseFloat(inKey.price - discountPrice).toFixed(2)}</span>}

          </td>
          <td className="hidden-box">{ !this.state.cartInfo.checkout_is_lander && qty}</td>
          <td>
          {discountPrice >0 && <span className="product-price"> $ {parseFloat( (price * qty) - (discountPrice * qty) ).toFixed(2)}</span>}
              {Math.ceil(discountPrice)<1 && <span className="product-price">
                {this.state.cartInfo.currency ? this.state.cartInfo.currency : '$' } {parseFloat(price * qty).toFixed(2)}
              </span>}
          </td>
        </tr>
      )

      //Add the upsell product

    });
    
    ls.set("totalOfferDiscount",totalOfferDiscount);

    processUpsell && Object.keys(processUpsell).forEach((key) => {
      let inKey = processUpsell[key];
      let price = inKey.price ? parseFloat(inKey.price).toFixed(2) : '0.00';
      let qty = 'quantity' in inKey ? parseInt(inKey.quantity) : 1;

      items_2.push(

        <tr key={key}>
          <td>
            <div className="product-image">
              <div className="product-image-inner">

                <ReactImageFallback
                  src={inKey.product_img_url ? inKey.product_img_url : noImage}
                  fallbackImage={this.img}
                  initialImage={this.img}
                  alt={inKey.product_name}
                  className="product-thumbnail__image" />


              </div>
              {!this.state.cartInfo.checkout_is_lander && <span>{qty}</span>}
            </div>
          </td>
          <td>
          <span className={"product-name " + ( inKey.product_name.length > 24 ? "custom-tooltip": "")} data-title={inKey.product_name}>{ Checkout.setEcllipseOnString(inKey.product_name, 24)}</span>
            <span className="product-color">{'variation' in inKey ? inKey.variation : ''}</span>
            <span className="product-color">{'price' in inKey ? 'Item price : $' + parseFloat(inKey.price).toFixed(2) : ''}</span>
          </td>
          <td className="hidden-box">{'quantity' in inKey ? inKey.quantity : 1}</td>
          <td>
            <span className="product-price">
              {this.state.cartInfo && this.state.cartInfo.currency ? this.state.cartInfo.currency : '$'} {parseFloat(price * qty).toFixed(2)}
            </span>
          </td>
        </tr>
      )

    });
    
    


    if(this.props.location.pathname === "/thank-you"){
      if(giftItems !== false && Object.keys(giftItems).length > 0){
        Object.keys(giftItems).forEach((key) => {
          let inKey  = giftItems[key];
          items_2.push(

            <tr key={key}>
              <td>
                <div className="product-image">
                  <div className="product-image-inner">
    
                    <ReactImageFallback
                      src={(inKey.image_url && inKey.image_url.length > 0) ? inKey.image_url[0] : noImage}
                      fallbackImage={this.img}
                      initialImage={this.img}
                      alt={inKey.name}
                      className="product-thumbnail__image" />
    
    
                  </div>
                  <span>1</span>
                </div>
              </td>
                <td>
                <span className={"product-name " + ( inKey.name.length > 24 ? "custom-tooltip": "")} data-title={inKey.name}>{ Checkout.setEcllipseOnString(inKey.name, 24) }</span>
                </td>
                <td>
                  <span className="product-name"></span>
                </td>
                <td><span className="gift-item-icon"><i className="fa fa-gift"></i><b className="text-success">Free</b></span></td>
              </tr>
            
            );
        });
      }
    }

    if (items_2.length < 1) {
      items_2.push(
        <tr>
          <td className="text-center text-danger">
            <img src={cartEmpty} className="" alt="Cart empty" />
          </td>
        </tr>
      );
    }
    return items_2;
  }
}

class ProductCharges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processUpsell: null,
      discount: '0.00',
      coupon: (ls.get('coupon') && ls.get('coupon_valid') === true) ? ls.get('coupon') : '',
      shipping: {
        id: "",
        key: "",
        name: "",
        price: "0.00"
      },
      cartInfo: (this.props.cartInfo) ? this.props.cartInfo : null,
      tax: (this.props.tax) ? this.props.tax : false,
      loading: false,
      coupon_valid: true,
      redeem_coupons_with_amount:ls.get('redeem_coupons_with_amount'),
      reload : false
    };
    this.removeStorageAction = this.removeStorageAction.bind(this);
    this.updateRedeemProps = this.updateRedeemProps.bind(this);
    this.removeCoupon = this.removeCoupon.bind(this);
    this.applyCoupon = this.applyCoupon.bind(this);
  }

  sweetError(msg) {

    if (msg) {
      swal({
        title: "OOPS!",
        text: msg,
        icon: "error",
      });
    }

  }

  async componentDidMount() {
    this.setState({ loading: false })
  }

  async componentWillMount() {
    this.setState({ loading: true })
    let discountDetail = await Checkout.getDiscountDetail();
    let response = await Checkout.getProspect();
    let upsellDetails = await Checkout.getProcessUpsellData();
    let cart
    if (this.state.cartInfo == null) {
      cart = await Checkout.getCartData();
    } else {
      cart = { cartInfo: this.state.cartInfo }
    }

    const { history } = this.props;
    if (!cart) {
      history.push('/404');
      return 0;
    } else if (cart && cart.cartInfo && cart.cartInfo.checkout_online_status === false) {
      history.push('/404');
      return 0;
    }

    if (cart && cart.cartInfo && cart.cartInfo.shopDetails.promo_code === false) {
      ls.remove('discount');
      ls.remove('coupon');
    }

    let shippingMethods = cart.cartInfo.shippingMethods;
    let shippingVal = response.shipping;
    
    
    if (response?.shipping && response?.shipping?.length > 0) {      
      let shippingData = shippingMethods && Object.keys(shippingMethods).length ? Object.values(shippingMethods).find(elm => elm.shipping_method_crm_id === shippingVal) : {};
      shippingData = await this.getCountryShippingdetail(shippingData,shippingVal);
      this.setState({ shipping: shippingData, discount: discountDetail.discount, coupon: discountDetail.coupon, processUpsell: upsellDetails, reload: (discountDetail.coupon && discountDetail.coupon !== '') ? true : false});
    } else {
      this.setState({shipping:{}, discount: discountDetail.discount, coupon: discountDetail.coupon, processUpsell: upsellDetails, reload: (discountDetail.coupon && discountDetail.coupon !== '') ? true : false});
    }

    this.removeStorageAction();
  }


  removeStorageAction() {
    try {
      if (this.props.location.pathname === '/thank-you') {
        this.props.updateStep('thank')
      }
    } catch (e) {
      console.log("Error", e);
    }
  }


  async componentWillReceiveProps(props) {

    let cartDetails = props.cartInfo;
    if (!cartDetails) {
      return 0
    }
    let stateVal = {};
    if (cartDetails && cartDetails.shopDetails.promo_code === false) {
      ls.remove('discount');
      ls.remove('coupon');
    }

    let prospect = await Checkout.getProspect();
    stateVal.shipping = {};
    if (prospect.shipping !== "") {
      let shippingData = cartDetails && cartDetails.shippingMethods && Object.keys(cartDetails.shippingMethods).length ? Object.values(cartDetails.shippingMethods).find(elm => elm.shipping_method_crm_id === prospect.shipping) : {};
      stateVal.shipping =  await this.getCountryShippingdetail(shippingData,prospect.shipping); // shippingData;
    }

    if (props && props.tax) {
      stateVal.tax = props.tax;
    }else{
      stateVal.tax = false;
      stateVal.shipping =  {
        id: "",
        key: "",
        name: "",
        price: "0.00"
      }
    }
    
    if(ls.get("coupon_valid")!==null)
    {
      stateVal.coupon_valid=ls.get("coupon_valid");
    }

    this.setState(stateVal);
  }

  onChange(e) {
    e.preventDefault();
    let str = e.target.value;
    this.setState({ coupon: str });
  }

  toggle() {
    this.setState({
      loading: !this.state.loading,
      progress: 0.5
    });
  }


  async applyCoupon() {   
    this.toggle();
    let statusRes = await Checkout.applyDiscountCodeWithOrderTotal(this.state.coupon, true);
    
    if(statusRes !==false && 'discount_amount' in statusRes){
        let tax = await Checkout.calculateTax();
        this.props.updateTax(tax)
        this.setState({discount: statusRes.discount_amount.toString().replace(/,/g, ''),coupon_valid:true, coupon : statusRes.code, reload : true });
        ls.set("coupon_valid",true);
        this.setState({ reload : true });
    }else{
      this.setState({ reload : false });
    }
    this.toggle();
  }

  async removeCoupon() {
    let statusRes =  await Checkout.removeDiscountCode(this.state.coupon);
    let tax = await Checkout.calculateTax() 
    this.props.updateTax(tax)
    if (statusRes !== false) {
      this.setState({ discount: '0.00', coupon: '',coupon_valid:true, reload : true });
    }else{
      this.setState({ reload : false });
    }
  }

  getshippingMulti(){
    try{
      let cart = this.props.cartInfo;
      let offerDetails = cart && 'offerDetails' in cart && cart.offerDetails ? cart.offerDetails : {};
      let allCycle = 0;
      
      Object.keys(offerDetails).forEach((key)=>{
        let prepaid  = offerDetails[key] && 'prepaid' in offerDetails[key] ? offerDetails[key]['prepaid'] : null;;
        
        if(prepaid){
          let cycles = prepaid && 'terms' in prepaid && prepaid['terms'] && prepaid['terms'].length >0 && prepaid['terms'][0] && prepaid['terms'][0]['cycles']? prepaid['terms'][0]['cycles'] : 0;
          allCycle = allCycle + cycles;
        }
      });
      return allCycle;
    }catch(e){
      console.log("Error:",e);
    }
  }
  calculateCouponReference(subtotal,shippingAmount){
    try{
      let cart = {'cartInfo': this.props.llConfig.cartData};
      let discountAmount = 0;
      let refrenceDetail = cart && cart.cartInfo && cart.cartInfo.refrence_discount ? cart.cartInfo.refrence_discount : {};
      let discountCode = refrenceDetail && refrenceDetail.discount_code ? refrenceDetail.discount_code : "";

      if(!refrenceDetail) return {"coupon":discountCode,"discount":discountAmount};

      //discount on shipping 
      if(refrenceDetail && refrenceDetail.discount_on === 'shipping'){
        if(refrenceDetail.discount_type === "percentage"){
          discountAmount = shippingAmount ? (shippingAmount * refrenceDetail.discount_value)/100 : 0;
        }
      }

      //discount on cart item
      if(refrenceDetail && refrenceDetail.discount_on === 'cart'){
        if(refrenceDetail.discount_type === "percentage"){
          discountAmount = subtotal ? (subtotal * refrenceDetail.discount_value)/100 : 0;
        }

        if(refrenceDetail.discount_type === "fixed_amount"){
          discountAmount = refrenceDetail.discount_value > subtotal ? subtotal : refrenceDetail.discount_value;
        }
      }

      return {"coupon":discountCode,"discount":discountAmount};
    }catch(e){
      return {"coupon":"","discount":0};;
    }
  }

  async getCountryShippingdetail(shippingData,shippingId){
    try{
      //V2 first we search on that
      let tempShipping = null;
      let shippingOnCheckoutConfig = ls.get("shippingOnCheckoutConfig");
      if(shippingOnCheckoutConfig && shippingOnCheckoutConfig.length>0){
        
        for(let k=0;k<shippingOnCheckoutConfig.length;k++){
          if(shippingOnCheckoutConfig[k]['shipping_method_crm_id'] === shippingId){
            tempShipping = shippingOnCheckoutConfig[k];
            break
          }
        }

        if(tempShipping!=null){
          return tempShipping;
        }
      }
      
      let checkout_country_based_shipping_enable = this.state.cartInfo && 'checkout_country_based_shipping_enable' in this.state.cartInfo  &&  this.state.cartInfo.checkout_country_based_shipping_enable ? this.state.cartInfo.checkout_country_based_shipping_enable : false;
      let country_based_shipping = this.state.cartInfo && 'country_based_shipping' in this.state.cartInfo && this.state.cartInfo.country_based_shipping ? this.state.cartInfo.country_based_shipping : [];
      
      if(!checkout_country_based_shipping_enable || !this.state.cartInfo) return shippingData;
      

      for(let i=0;i<country_based_shipping.length;i++){
        let shippingMethods = country_based_shipping[i]['shipping_methods'];
        for(let j=0;j<shippingMethods.length;j++){
          if(shippingMethods[j]['shipping_method_crm_id'] === shippingId){
            tempShipping = shippingMethods[j];
            break;
          }
        }

        if(tempShipping!==null){
          shippingData = tempShipping;
          break;
        }
      }
      
      return shippingData;
    }catch(e){
      return shippingData;
    }
  }

  async updateRedeemProps(e){
    let tax = await Checkout.calculateTax();
    this.props.updateTax(tax)
    this.setState({redeem_coupons_with_amount:e});
  }

  render() {
    
    let cycle= this.getshippingMulti();
    let showPromoCode
    if (this.props.cartInfo === false) {
      showPromoCode = this.state.cartInfo && this.state.cartInfo.shopDetails.promo_code ? this.state.cartInfo.shopDetails.promo_code : false;
    } else {
      showPromoCode = this.props.cartInfo && this.props.cartInfo.shopDetails.promo_code ? this.props.cartInfo.shopDetails.promo_code : false;
    }
    showPromoCode = this.props.location.pathname !== '/thank-you' && ls.get('prospectId') ? showPromoCode : false;

    //get total Amount
    let products;
    if (this.props.cartInfo === false) {
      products = this.state.cartInfo && Object.keys(this.state.cartInfo).length > 0 && this.state.cartInfo.shopDetails.products ? this.state.cartInfo.shopDetails.products : {};
    } else {
      products = this.props.cartInfo && Object.keys(this.props.cartInfo).length > 0 && this.props.cartInfo.shopDetails.products ? this.props.cartInfo.shopDetails.products : {};
    }

    var totalAmount = 0.00;
    var subtotal = 0.00; 
    let checkout_enable_tax = ls.get('checkout_enable_tax');
    let couponDiscount = ls.get('discount') ?? 0;
    let removed_items = (ls.get('removed_items')) ? ls.get('removed_items') : false;
    products && Object.keys(products).forEach((key)=>{

      let price = products[key]['price'] ? parseFloat(products[key]['price']).toFixed(2) : '0.00';
      let qty = products[key]['quantity'] ? parseInt(products[key]['quantity']) : 1;

      if(removed_items !== false && removed_items.indexOf(key) === -1){
        totalAmount = parseFloat(price * qty) + totalAmount;  
      }else if (removed_items === false) {
        totalAmount = parseFloat(price * qty) + totalAmount;
      }
    });

    //one click upsell product
    let oneClickUpsellsatLocal = ls.get('oneClickUpsells');
    oneClickUpsellsatLocal && Object.keys(oneClickUpsellsatLocal).length > 0 && Object.values(oneClickUpsellsatLocal).forEach((item)=>{
      let price = item.price ? parseFloat(item.price).toFixed(2) : '0.00';
      totalAmount = parseFloat(totalAmount) + parseFloat(price);
    });

    subtotal = totalAmount;


    let totalOfferDiscount = ls.get("totalOfferDiscount");
    let shipping_initial_amount = this.state.shipping && this.state.shipping.initial_amount ? this.state.shipping.initial_amount : 0.00;
    
    //Threshold logic
    let shppingThresholdAmount = 0;
    let isApplyShppingThresholdAmount = false;
    let threshold_amount = this.state.shipping && this.state.shipping.threshold_amount;
   
    if(this.state.shipping && this.state.shipping.initial_amount &&  threshold_amount!=='0.00' && threshold_amount!==null && (subtotal - totalOfferDiscount) >= threshold_amount){
      shppingThresholdAmount = this.state.shipping && this.state.shipping.threshold_charge_amount ? this.state.shipping.threshold_charge_amount : 0;
      shipping_initial_amount = shppingThresholdAmount;
      isApplyShppingThresholdAmount = true;
    }
    //End Threshold logic

    // make shipping amount 0 if its a token based user and having free shipping
    if(this.state.cartInfo && this.state.cartInfo.token_based_user_free_shipping){
      shipping_initial_amount = 0;
      shppingThresholdAmount = 0;
    }

    let shippingAmount = parseFloat( (shipping_initial_amount), 10) ;
    shippingAmount = cycle > 0 ? shippingAmount * cycle : shippingAmount;

    totalAmount = parseFloat( (totalAmount), 10) + shippingAmount - parseFloat(couponDiscount, 10) + parseFloat((checkout_enable_tax && this.state.tax !== false && this.state.tax.tax !== undefined) ? this.state.tax.tax.total : "0.00" , 10);
    
    //order total 
    var refrenceDiscount = this.calculateCouponReference(subtotal, shippingAmount);
    let refDiscountAmount = refrenceDiscount && refrenceDiscount.discount  ? refrenceDiscount.discount : 0;
    
    totalOfferDiscount = totalOfferDiscount ? totalOfferDiscount : 0;
    totalAmount = totalAmount - totalOfferDiscount - refDiscountAmount;

    //Apply redeem coupons 
    let checkout_enable_smile_config = this.state.cartInfo && this.state.cartInfo.checkout_enable_smile_config ? this.state.cartInfo.checkout_enable_smile_config : false;
    
    let totalRedeem = 0;
    checkout_enable_smile_config && this.state.redeem_coupons_with_amount && Object.values(this.state.redeem_coupons_with_amount).forEach((val)=>{
      totalRedeem = totalRedeem + val['discount'];
    })

    totalRedeem = totalRedeem>subtotal ?  subtotal : totalRedeem;

    totalAmount = totalAmount - totalRedeem;
    totalAmount = parseFloat(totalAmount);
   if(totalAmount > 0){
      totalAmount = parseFloat(totalAmount).toFixed(2);
      Checkout.updateOrdertotal(totalAmount);
      this.props.manageCartSubTotalAmount(totalAmount);
    }else{
      totalAmount = 0.00;
    }

    let processUpsell = this.state.processUpsell && Object.keys(this.state.processUpsell).length > 0 && this.state.processUpsell ? this.state.processUpsell : {};

    processUpsell && Object.keys(processUpsell).forEach((key) => {

      let price = processUpsell[key]['price'] ? parseFloat(processUpsell[key]['price']).toFixed(2) : '0.00';
      let qty = processUpsell[key]['quantity'] ? parseInt(processUpsell[key]['quantity']) : 1;
      subtotal = parseFloat(price * qty) + subtotal;
      totalAmount = parseFloat(price * qty) + parseFloat(totalAmount);
    });

    return (
      <React.Fragment>
        {
          showPromoCode === true && <div className="order-summery-top">

            <table className="table">
              <tbody>
                <tr>
                  <td>
                    <div className="row">
                      <div className={`with-tag  ${this.state.cartInfo.checkout_enable_single_page ? 'col-sm-12' : 'col-sm-8' }`}>
                        <input type="text" name="coupon" placeholder="Discount Code" className="form-control gift-card" onChange={e => this.onChange(e)} readOnly={(ls.get('coupon_valid') === true) ? true : false} value={(ls.get('coupon_valid') === true) ? "" : this.state.coupon}/>
                        { (ls.get('coupon_valid') === true) ? <div className={`alert alert-dark fade show mt-3 ${this.state.cartInfo.checkout_enable_single_page ? 'op-border' : '' }`} role="alert">
                              <i className="fa fa-tags"></i> {ls.get('coupon')}
                              <button type="button" className='close remove-single-option' onClick={(e) => {  this.removeCoupon(); }}>
                                  <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg_375hu" focusable="false" aria-hidden="true">
                                      <path d="M11.414 10l4.293-4.293a.999.999 0 1 0-1.414-1.414L10 8.586 5.707 4.293a.999.999 0 1 0-1.414 1.414L8.586 10l-4.293 4.293a.999.999 0 1 0 1.414 1.414L10 11.414l4.293 4.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L11.414 10z" fill-rule="evenodd"></path>
                                  </svg>
                              </button>
                          </div> : "" }
                      </div>

                      <div className={`text-right  ${this.state.cartInfo.checkout_enable_single_page ? 'col-sm-12' : 'col-sm-4' }`}>
                      <LaddaButton
                              className="apply-but btn btn-secondar btn-sm"
                              type="submit"
                              loading={this.state.loading}
                              data-size={L}
                              data-style={SLIDE_UP}
                              data-spinner-size={30}
                              data-spinner-color="#ddd"
                              data-spinner-lines={12}
                              disabled={(ls.get('coupon_valid') === true) ? true : false}
                              onClick={() => this.applyCoupon()}
                            >
                              Apply
                        </LaddaButton>

                      </div>
                      
                      {!this.state.coupon_valid && <div className="col-sm-8 text-danger text-left mt-3">Coupon Error: Invalid Promo Code</div>}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        }

        
        {this.state.cartInfo && this.state.cartInfo.checkout_enable_smile_config && this.props.history.location.pathname === '/payment' &&   <SmileIOPage cartInfo={this.state.cartInfo} {...this.props} updateRedeemProps={(e)=>this.updateRedeemProps(e)}/>}
                         
       
          <div className="charges">
              <table className="table">
          <tbody>
            <tr className="charges-line">
              <td>
                <span>Subtotal</span>
              </td>
              <td >
                <span>{this.state.cartInfo && this.state.cartInfo.currency ? this.state.cartInfo.currency : '$'} { parseFloat( (subtotal), 10).toFixed(2)}</span>
              </td>
            </tr>
            <ShippingCharge token_based_user_free_shipping={this.state.cartInfo.token_based_user_free_shipping} shippingPrice={this.state.shipping && this.state.shipping.initial_amount ? parseFloat(this.state.shipping.initial_amount).toFixed(2) :0.00} cycle={cycle} shppingThresholdAmount={shppingThresholdAmount} isApplyShppingThresholdAmount={isApplyShppingThresholdAmount}/>
            <tr className="charges-line">
              <td>
                <span>Tax { ((checkout_enable_tax && this.state.tax && this.state.tax.tax) ? this.state.tax.tax.pct  + "% (included)" : "")}   </span>
              </td>
              <td>
                  <span>$ { parseFloat((checkout_enable_tax && this.state.tax !== false && this.state.tax.tax) ? this.state.tax.tax.total : "0.00",10).toFixed(2)}</span>
              </td>
            </tr>
            
            {couponDiscount > 0 && <tr className="charges-line">
              <td>
                <span>Discount</span>

              </td>

              <td>
                <span>{(parseFloat(couponDiscount) > 0) ? "-" : "" } $ { parseFloat(couponDiscount,10).toFixed(2)}</span>
              </td>

            </tr>}

            {totalOfferDiscount > 0 && <tr className="charges-line">
              <td>
                <span>Offer Discount</span>

              </td>

              <td>
                <span> - $ { parseFloat(totalOfferDiscount,10).toFixed(2)}</span>
              </td>

            </tr>}

            {
              refrenceDiscount && refrenceDiscount.coupon !== "" && <tr className="charges-line">
                <td>
                <span><i className="fa fa-tags" aria-hidden="true"></i> {refrenceDiscount.coupon}</span>

              </td>

              <td>
                <span> - $ { parseFloat(refrenceDiscount.discount,10).toFixed(2)}</span>
              </td>
              </tr>}
              
              {totalRedeem >0 &&<tr className="charges-line" >
              <td>
                <span>Reward </span>
              </td>
              <td>
                <span>-$ {parseFloat(totalRedeem,10).toFixed(2)}</span>
              </td>
            </tr>}

            
          </tbody>
          <tfoot>
            <tr className="main-total">
              <td>
                <span>Total</span>
              </td>
              <td>
                <span className="small-text">USD</span>
                <span className="big-text">
                  $
                  {(
                      parseFloat((totalAmount), 10)
                    ).toFixed(2)}
                  </span>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        
        
      </React.Fragment>

    );
  }
}

class ShippingCharge extends Component {
  render() {
    let cycle =  this.props.cycle ? this.props.cycle : 0;
    let shippingPrice = parseFloat( cycle > 0 ?  this.props.shippingPrice * cycle : this.props.shippingPrice, 10).toFixed(2);
    if(this.props.token_based_user_free_shipping){
      shippingPrice = 0;
    }
    return (
      <tr className="charges-line">
        <td>
          <span>Shipping</span>
        </td>
        <td>
          {
            this.props.isApplyShppingThresholdAmount === true || this.props.token_based_user_free_shipping ? 
              <React.Fragment><span style={{marginLeft:"5px"}}>$ {parseFloat(this.props.shppingThresholdAmount,10).toFixed(2)}</span> <span>{shippingPrice > 0 ? <strike>$ {shippingPrice}</strike> : '' }</span></React.Fragment> 
              : 
              <React.Fragment><span>$ {shippingPrice}</span></React.Fragment>
          }
        </td>
      </tr>
    );
  }
}