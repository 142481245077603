import React, { Component } from "react";
import withUnmounted from '@ishawnwang/withunmounted';
import { connect } from "react-redux";

function mapStateToProps(state) {
  return {
    llConfig: state.llConfig,
    storeCountries: state.countries
  };
}

class SidebarSkeleton extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="bg-gray">
          <div className="order-summery">
            <div className="ph-item border-0 p-0 bg-transparent">

              <div className="ph-col-12">
                <div className="row">
                  <div className="ph-col-2 mb-2">
                    <div className="ph-row">
                      <div className="ph-col-12 mb-0"></div>
                      <div className="ph-col-12 mb-0"></div>
                      <div className="ph-col-12 mb-0"></div>
                      <div className="ph-col-12 mb-0"></div>
                      <div className="ph-col-12 mb-0"></div>
                      <div className="ph-col-12 mb-0"></div>
                    </div>
                  </div>
                  <div className="ph-col-8 px-1">
                    <div className="ph-row">
                      <div className="ph-col-12 mb-0 empty"></div>
                      <div className="ph-col-12 mb-0"></div>
                      <div className="ph-col-12 mb-0 empty"></div>
                      <div className="ph-col-12 mb-0"></div>
                    </div>
                  </div>

                  <div className="ph-col-2">
                    <div className="ph-row">
                      <div className="ph-col-12 mb-0"></div>
                      <div className="ph-col-12 mb-0"></div>
                      <div className="ph-col-12 mb-0"></div>
                      <div className="ph-col-12 mb-0"></div>
                      <div className="ph-col-12 mb-0"></div>
                      <div className="ph-col-12 mb-0"></div>
                    </div>
                  </div>
                  <div className="ph-col-4 mb-2">
                    <div className="ph-row">
                      <div className="ph-col-12 mb-0"></div>
                    </div>
                  </div>
                  <div className="ph-col-4 mb-2 empty">
                    <div className="ph-row empty">
                      <div className="ph-col-12 mb-0 empty"></div>
                    </div>
                  </div>
                  <div className="ph-col-4 mb-2">
                    <div className="ph-row">
                      <div className="ph-col-12 mb-0"></div>
                    </div>
                  </div>
                  <div className="ph-col-4 mb-2">
                    <div className="ph-row">
                      <div className="ph-col-12 mb-0"></div>
                    </div>
                  </div>
                  <div className="ph-col-4 mb-2 empty">
                    <div className="ph-row empty">
                      <div className="ph-col-12 mb-0 empty"></div>
                    </div>
                  </div>
                  <div className="ph-col-4 mb-2">
                    <div className="ph-row">
                      <div className="ph-col-12 mb-0"></div>
                    </div>
                  </div>
                  <div className="ph-col-4 mb-2">
                    <div className="ph-row">
                      <div className="ph-col-12 mb-0"></div>
                    </div>
                  </div>
                  <div className="ph-col-4 mb-2 empty">
                    <div className="ph-row empty">
                      <div className="ph-col-12 mb-0 empty"></div>
                    </div>
                  </div>
                  <div className="ph-col-4 mb-2">
                    <div className="ph-row">
                      <div className="ph-col-12 mb-0"></div>
                    </div>
                  </div>

                  <div className="ph-col-4 mb-2">
                    <div className="ph-row">
                      <div className="ph-col-12 mb-0"></div>
                    </div>
                  </div>
                  <div className="ph-col-4 mb-2 empty">
                    <div className="ph-row empty">
                      <div className="ph-col-12 mb-0 empty"></div>
                    </div>
                  </div>
                  <div className="ph-col-4 mb-2">
                    <div className="ph-row">
                      <div className="ph-col-12 mb-0"></div>
                      <div className="ph-col-12 mb-0"></div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

}

const ConnectedSidebarSkeleton = connect(
  mapStateToProps,
  null
)(SidebarSkeleton);

export default withUnmounted(ConnectedSidebarSkeleton);