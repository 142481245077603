import React, { Component } from "react";
import withUnmounted from '@ishawnwang/withunmounted'
import Checkout from '../classes/checkout';
import ls from "local-storage";
import $ from "jquery";
import swal from 'sweetalert';

class SmileIOPage extends Component {
    constructor(props) {
        super(props);
        this.initJS  = this.initJS.bind(this);
        this.eventListeners = new Map();
        this.bindRedeemClickEvent = this.bindRedeemClickEvent.bind(this);
        this.redeemClickBind = this.redeemClickBind.bind(this);
        this.pushDiscountCodeForProcess = this.pushDiscountCodeForProcess.bind(this);
        this.state = {smileJSLoaded:false, jsLinkUpdated:false,showUI:false, smile_io:ls.get("smile_io"), prospectId:ls.get("prospectId")};
    }

    async bindRedeemClickEvent(eventName, callback){
        try{
            document.getElementById('sweettooth-redeem-button').addEventListener(eventName, callback, false);
            this.eventListeners.set(`document:${eventName}`, { eventName, callback })
        }catch(e){
            console.log("Exception while",e);
        }
    }

    redeemClickBind(e){
        e.preventDefault();

        var $pointsProductsDropdown = $('.sweettooth-points-products-dropdown');
        var $selectedPointsProduct = $('.sweettooth-points-products-dropdown').find('.sweettooth-points-products-select :selected');
        var pointsProductId = $selectedPointsProduct.val();
        var thisIns = this;
        var discountCode = "";
        try{
            $("#sweettooth-redeem-button").addClass('show-loader');
            // Hit the Smile.io API to spend customer's points
            window.SweetTooth.purchasePointsProduct(pointsProductId).then(function(pointsPurchase) {
                // Clear the field error if there was one previously
                $pointsProductsDropdown.find('.field').removeClass('field--error');
                
                discountCode = pointsPurchase && pointsPurchase.fulfilled_reward &&  pointsPurchase.fulfilled_reward.code

                if(!discountCode){
                    return false;
                }

                // Immediately update the customers points balance
                $('.sweettooth-points-balance').text(window.SweetTooth.customer.points_balance - pointsPurchase.points_spent);

                /**
                 * Update customer's points balance based on the server's value.
                 * This should be the same as the above but always nice to confirm.
                 */
                window.SweetTooth.fetchCustomer().then(function(customer) {
                    $('.sweettooth-points-balance').text(customer.points_balance);

                    if(customer.points_balance<1){
                        $("#sweettooth-redeem-button").removeClass('btn-primar');
                        $("#sweettooth-redeem-button").addClass('btn-primarybtn--disabled');
                        $("#sweettooth-redeem-button").addClass('btn--disabled');
                        $("#sweettooth-redeem-button").attr('disabled', true);
                    }
                
                    
                });

                
            }).catch(function(err) {
                thisIns.showError(err);
            }).then(function() {
                /**
                 * This block runs whether the call succeeds or fails, kind of like finally() in other languages.
                 * Source: http://stackoverflow.com/a/35999141/540194
                 */
               
                thisIns.pushDiscountCodeForProcess(discountCode);
                $("#sweettooth-redeem-button").removeClass('show-loader');
                //Add logic for disable button
            });

        }catch(e){
            console.log(e);
        }
    }

    showError(err){
        try{
            let errorRes = err && err.message ? JSON.parse(err.message) :{};
            let errorMessage = errorRes && errorRes.error && errorRes.error.message ? errorRes.error.message : "Something went wrong when processing PointsProduct";

            swal({
                title: "OOPS!",
                text: errorMessage,
                icon: "error",
              });

        }catch(e){
            console.log("Error",e);
        }
    }

    bindClickEventOnRedeem(){
        try{
            let thisIns = this;
            let intervalIns =  setInterval(function(){ 
                if(document.getElementById('sweettooth-redeem-button')){
                    thisIns.bindRedeemClickEvent('click',thisIns.redeemClickBind.bind(this));
                    clearInterval(intervalIns);
                }
               }, 500);
        }catch(e){
            console.log("Error",e);
        }
    }

    async pushDiscountCodeForProcess(discountCode){
        try{
            if(!discountCode){
                return false;
            }

            let coupons = ls.get("redeemCoupons");
            
            if(!coupons){
                coupons = [];
                coupons.push(discountCode);
            }else{
                coupons.push(discountCode);
            }

            ls.set("redeemCoupons",coupons);
            let redeemCouponsWithPrice = await Checkout.getRedeemCouponDiscount();
            if(redeemCouponsWithPrice){
                this.props.updateRedeemProps(redeemCouponsWithPrice);
            }
            
            return true;
        }catch(e){
            console.log("Error",e);
            return false;
        }
    }

    componentWillReceiveProps(nextProps){
        //In each call of props update
        
    }

    componentDidMount(){
        //After render
    }


    componentWillMount(){
        //Befor render
        let smile = ls.get("smile_io");
        this.setState({smile_io:smile});
        this.initJS();
    }

    initJS(){
        try{
            const that = this;
            if(document.getElementById("smile-io")){
                document.getElementById("smile-io").remove();
            }

            
            let smileIOScript = document.createElement('script');
            smileIOScript.src = "https://cdn.sweettooth.io/assets/storefront.js";
            smileIOScript.type = "text/javascript"
            smileIOScript.id = "smile-io"
            smileIOScript.async = true;
            document.getElementsByTagName("head")[0].appendChild(smileIOScript);
            smileIOScript.onload = ()=>{
                that.setState({jsLinkUpdated: true});
                that.initRewardUI();
            };
        }catch(e){
            console.log("Error:",e);
        }
    }

    initRewardUI(){
        try{
            let thisIns = this;
            let intervalCall = 500;
            let intervalIns =  setInterval(function(){ 
                intervalCall = intervalCall + 500;
                if(window.SweetTooth){
                    window.smileIOcall();
                    clearInterval(intervalIns);
                    thisIns.setState({showUI:true});
                    thisIns.bindClickEventOnRedeem();
                }
                
                //awaiting the call for till 20 second
                if(intervalCall>20000){
                    clearInterval(intervalIns);
                    thisIns.setState({showUI:null});
                }

               }, 500);
        }catch(e){
            console.log("Error:",e);
        }
    }


    render() {
        
        return (
            <React.Fragment>
                {this.state.smile_io && this.state.smile_io.customer_detail && this.state.smile_io.customer_detail.customer_auth_digest && <div>

                    {this.state.showUI === false && <div className="row coupon-sctn" >
                        <div className="col-sm-12">
                            <div className="ph-item px-0" style={{borderBottom:"1px solid #e0e0e0"}}>
                                <div className="ph-col-12">      
                                    <div className="ph-row">
                                        <div className="ph-col-4"></div>
                                        <div className="ph-col-8 empty"></div>
                                        <div className="ph-col-6"></div>
                                        <div className="ph-col-6 empty"></div>
                                        <div className="ph-col-10 extra-big"></div>								
                                        <div className="ph-col-2 extra-big button-block"></div>                               
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}


                    <div className="order-summery-top" style={{"display":(this.state.showUI === true? "block":"none")}}>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="row">
                                            <div className="col-sm-12">

                                                <div class="sweettooth-init"
                                                data-channel-api-key={this.props.cartInfo && this.props.cartInfo.checkout_smile_public_key ? this.props.cartInfo.checkout_smile_public_key: ""}
                                                data-external-customer-id={this.state.smile_io && this.state.smile_io.customer_detail && this.state.smile_io.customer_detail.external_id ? this.state.smile_io.customer_detail.external_id : ""}
                                                data-customer-auth-digest={this.state.smile_io && this.state.smile_io.customer_detail && this.state.smile_io.customer_detail.customer_auth_digest ? this.state.smile_io.customer_detail.customer_auth_digest : ""}>
                                                </div>
                                                <div class="sweettooth-launcher"></div>
                                                <div class="sweettooth-points-products-dropdown dropdown-io"></div>  
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> 

                </div>}
            </React.Fragment>
        )
    }
}
export default withUnmounted(SmileIOPage);        